<template>
  <v-card>
    <v-card-title>
      Transferência de carteira

      <v-spacer></v-spacer>

      <v-btn
        color="info"
        class="me-3"
        @click="modalTransfer = true"
      >
        <v-icon
          size="18"
          class="me-1"
        >
          {{ icons.mdiTransfer }}
        </v-icon>
        Transferir
      </v-btn>
    </v-card-title>

    <v-card-text class="d-flex">
      <v-col
        cols="5"
      >
        <v-row>
          <v-autocomplete
            v-model="userId"
            :items="vendors"
            :loading="loadingVendorList"
            :disabled="!vendors.length"
            outlined
            dense
            label="Nome vendedor"
            item-text="name"
            item-value="id"
            @input="onInput"
          />
        </v-row>
      </v-col>
      <v-spacer></v-spacer>

      <span class="count-selected">{{ salesListId.length }}</span>
    </v-card-text>

    <v-data-table
      v-model="itemTableSelected"
      :headers="headers"
      :items="itemsTable"
      :loading="loading"
      loading-text="Carregando dados..."
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      hide-default-footer
      show-select
      @page-count="pageCount = $event"
    >
    </v-data-table>

    <v-dialog
      v-model="modalTransfer"
      width="500"
    >
      <div>
        <v-card>
          <v-card-title>
            Selecione o vendedor destino
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="newVendorId"
                  :items="vendors"
                  :loading="loadingVendorList"
                  :disabled="!vendors.length"
                  outlined
                  dense
                  label="Nome vendedor"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>

            <v-row class="d-flex justify-content-center">
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                text
                @click="transferSales()"
              >
                <span v-if="loadingSpinnerModal === false">
                  Transferir
                </span>

                <v-progress-circular
                  v-if="loadingSpinnerModal === true"
                  indeterminate
                  :size="25"
                  color="gray"
                >
                </v-progress-circular>
              </v-btn>
              <v-btn
                color="error"
                text
                @click="clearModal()"
              >
                Cancelar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiPlus, mdiTransfer } from '@mdi/js'

export default {
  data() {
    return {
      loadingSpinnerModal: false,
      itemTableSelected: [],
      salesListId: [],
      loading: false,
      userId: '',
      newVendorId: '',
      itemsTable: [],
      headers: [
        {
          text: 'NOME CLIENTE', value: 'client_name', sortable: false, align: 'center',
        },
        {
          text: 'TELEFONE', value: 'phone_cell', sortable: false, align: 'center',
        },
        {
          text: 'ENDEREÇO', value: 'address', sortable: false, align: 'center',
        },
        {
          text: 'CIDADE', value: 'city', sortable: false, align: 'center',
        },
        {
          text: 'PLANO', value: 'sales_plan', sortable: false, align: 'center',
        },
        {
          text: 'MODELO', value: 'vehicle_model', sortable: false, align: 'center',
        },
        {
          text: 'STATUS', value: 'contract_status', sortable: false, align: 'center',
        },
        {
          text: 'Nº PROPOSTA', value: 'proposal_number', sortable: false, align: 'center',
        },
      ],
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 999999999,
      },
      vendors: [],
      loadingVendorList: false,
      modalTransfer: false,
      icons: {
        mdiPlus,
        mdiTransfer,
      },
    }
  },

  watch: {
    itemTableSelected(select) {
      const items = []
      select.map(item => {
        items.push(item.id)
      })

      this.salesListId = items
    },
  },
  created() {
    this.loadingVendorList = true
    this.listEmployees()
  },

  methods: {
    clearModal() {
      this.newVendorId = ''
      this.modalTransfer = false
    },
    async onInput() {
      await this.listSalesByVendor()
    },

    listEmployees() {
      axiosIns.get('api/v1/records/user/index')
        .then(res => {
          this.vendors = res.data.data
          this.loadingVendorList = false
        })
    },

    async listSalesByVendor() {
      this.loading = true
      const body = {
        users_id: this.userId,
      }
      await axiosIns.post('/api/v1/integration/contract/filter_contract_transfer_vendor', body)
        .then(res => {
          this.itemsTable = res.data.data
        })
      this.loading = false
    },

    async transferSales() {
      this.loadingSpinnerModal = true
      const body = {
        users_id_old: this.userId,
        users_id_new: this.newVendorId,
        contracts_id: this.salesListId,
      }

      await axiosIns.post('/api/v1/integration/contract/transfer_wallet', body)
        .then(res => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: `${res.data.data.mensage}`,
            showConfirmButton: false,
            timer: 4000,
          })
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao transferir vendas!',
            showConfirmButton: false,
            text: error,
            timer: 2500,
          })
        })
      this.loadingSpinnerModal = false
      this.clearModal()
      this.listSalesByVendor()
    },
  },
}
</script>
<style>
.count-selected{
  font-size: 25px;
  margin-right: 20px;
}
</style>
